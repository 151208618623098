.ui.inverted.menu.topMenu {
  background: #1c475c;
  height: 5.5rem;
}
.ui.menu .item.menuText {
  font-weight: bolder;
}

.ui.inverted.menu .item.blue.item.menuText {
  color: #4183c4;
}

.ui.inverted.menu .active.item.menuText {
  background: rgba(255, 255, 255, 0.15);
}

.workspace-navbar {
  background: #f7f7f7;
}

.ui.menu .item > i.icon {
  font-size: 1.5rem;
}

.ui.menu .item:before {
  width: 0px;
}

@media (min-width: 2000px) {
  .ui.inverted.menu.topMenu {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 767px) {
  .ui.menu .item.menuText {
    min-width: 15rem !important;
    padding: 24px;
  }
}
