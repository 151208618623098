.header.card-header {
  font-size: 1.6rem !important;
  color: rgb(0, 91, 153) !important;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.card-group {
  justify-content: center !important;
}

.card-image:hover {
  background-color: #e0e1e2 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.card-image img {
  width: auto;
  height: auto;
  min-width: 200px !important;
  min-height: 300px !important;
  width: 100%;
  background: transparent !important;
  left: 50%;
  font-size: 3rem !important;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
}
.ui.card > .image {
  cursor: pointer;
  height: 290px;
}

.ui.raised.card:hover {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  z-index: 1;
  box-shadow: 1px 1px 1px 1px #73cffa, 1px 1px 1px 1px #73cffa, 1px 1px 1px 1px #73cffa;
}
.small-button {
  background: #ff5f08 !important;
}

.midsection-header {
  font-size: 1.7em !important;
  color: #fff !important;
  background: #1c475c !important;
  padding: 0.5em !important ;
}

.heroimage-label {
  width: 100%;
  background: transparent !important;
  color: #fff !important;
  left: 50%;
  font-size: 3rem !important;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 40%;
}

.heroimage-label + img {
  min-height: 300px;
}

.heroimage-sublabel {
  font-size: 1.5rem !important;
  text-align: center !important;
}

i.icon.angle.double.up:before,
i.icon.angle.double.down:before {
  margin-left: 10px;
}
i.file.alternate.icon.consumerIcons {
  display: inline;
  font-weight: bold;
  opacity: 1;
}
i.file.alternate.icon.consumerIcons::before {
  padding: 5px;
}
.ui.segments:not(.horizontal) > .segment:first-child {
  min-height: 700;
  width: auto;
  background: linear - gradient(rgb(0, 83, 138), rgb(85, 132, 161), rgb(182, 218, 241));
}
.ui.vertical.segment {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}
.second-segment {
  color: #fff !important;
}

@mixin consumer-schema-viewer {
  width: 100%;
  margin: 2rem 0 0 0;
}

.inspection-schema-viewer-wrapper {
  @include consumer-schema-viewer;
  margin-bottom: 2rem;
}

.inspection-schema-viewer-wrapper .preview-view-editor {
  @include consumer-schema-viewer;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.inspection-schema-viewer-wrapper .delivery-options-outer {
  @include consumer-schema-viewer;
  margin-bottom: 2rem;
}
.ui.popup > .ui.grid:not(.padded) {
  background-color: #465665;
}
.modals.dimmer .ui.modal {
  background-color: #465665;
  width: 500px;
}

#black-credit-card-button {
  display: none !important;
}

.modal-logo {
  margin-left: auto;
  margin-right: auto;
  height: max-content;
  width: 40%;
  margin-top: 1rem;
}

.ui.segments.consumers-order-root {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.ui.primary.button.consumer-buttons {
  width: 290px;
  margin-top: 2px;
}

// ------------------- payments css starts ------------------------
.payment {
  height: 40px;
  width: 200px;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  margin-bottom: 1rem;
  cursor: pointer;
}

.amount-header {
  padding-right: 100px;
}

.amount-number {
  float: right;
  padding-left: 30px;
  color: #184365;
}

.zoid-outlet {
  float: right;
}

.save-look-btn {
  width: 100px;
  margin-top: 1rem !important;
}
sup {
  font-size: 12px;
  color: palevioletred;
}
@media (max-width: 580px) {
  .ui.horizontal.segments {
    display: grid;
  }
  .tax-info > p {
    line-height: 1em !important;
  }
}
.tax-info {
  color: palevioletred;
  font-size: 11px;
}

// ------------------- payments css ends ------------------------

//--------media queries-----------//
@media (max-width: 580px) {
  .ui.segments.consumers-order-root {
    width: 80%;
  }

  .inspection-schema-viewer-wrapper .preview-view-editor {
    @include consumer-schema-viewer;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  .ui.primary.button.consumer-buttons {
    width: auto;
  }
  .amount-number {
    float: none;
    padding-left: 0px;
  }
  .payment {
    max-width: 215%;
  }

  .ui.label.heroimage-label {
    font-size: xx-large !important;
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .login-modal {
    width: auto !important;
  }
  .ui.stackable.grid.login-grid {
    margin-left: -14px !important;
    margin-right: -14px !important;
  }
}

@media (min-width: 440px) {
  .ui.modal > .close {
    top: -2.5rem !important;
    right: -2.5rem !important;
    color: #fff !important;
  }
}

@media (max-width: 1024px) {
  .ui.form .fields {
    flex-wrap: wrap;
  }
}

@media (min-width: 2000px) {
  .ui.segments.consumers-order-root {
    width: 80%;
  }
  .inspection-schema-viewer-wrapper .preview-view-editor {
    @include consumer-schema-viewer;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  .ui.primary.button.consumer-buttons {
    font-size: 1.4rem;
    width: auto;
  }
  .ui.segment {
    font-size: 1.4rem !important;
  }
}

//--------media queries end-----------//
