.fill-screen {
    align-items: center;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
}

h1 {
    font-size: 32px;
}

p {
    color: rgba(0, 0, 0, .59);
    font-size: 16px;
}

button {
    font-size: 16px !important;
}