.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.workspace-frame {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
}

.body-frame {
  display: flex;
  overflow-y: auto;
  flex-grow: 1;
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0rem;
  margin-right: 0rem;
  background: #f7f7f7;
}
.ui.segment {
  border: 1px solid #ccc;
  margin-bottom: 2rem;
  box-shadow: 0 1px 2px 0 #ccc;
  font-size: 1.2rem;
}

.ui.fullscreen.modal {
  left: auto !important;
}

img {
  // position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

//-------------- Font Size for App above HD resolution --------------------//

@media (min-width: 2000px) {
  .ui.radio.checkbox {
    font-size: 1.5rem;
  }
}
