@import "../../utilities/style.scss";

/* ------------  side schema selector css starts---------------------*/
.ui.vertical.menu {
  height: fit-content;
  width: 25%;
  .item:hover,
  .active.item,
  .active.item:hover {
    background: #43a2dc;
    color: white;
  }
}

.workspace-place-order {
  display: flex;
}

.place-order-header {
  padding: 15px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #184365;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  background: #f1f1f1;
}

.preview-view-editor {
  width: 80%;
  margin-left: 1%;
}
/* ------------  side schema selector css ends---------------------*/

/*--------------- skeleton css starts -------------------------*/
.skeleton-outer {
  display: flex;
}
.skeleton-schemalist {
  width: 20%;
  height: 50vh;
  margin-top: -2%;
  margin-left: 10%;
}

.skeleton-form {
  width: 65%;
  margin-right: 8%;
  height: 85vh;
  margin-left: -3%;
}
/*--------------- skeleton css ends -------------------------*/

.schema-delivery-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.workspace-placeorder .ui.header {
  color: #184365;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background: #f7fbff;
  width: 100%;
  height: auto;
  line-height: 1.5em;
}
.workspace-placeorder .ui.form {
  .field {
    > label {
      float: left;
      color: #184365;
    }
  }
}
.ui.fluid.dropdown {
  display: inline-block;
}
.ui.basic.button {
  float: right;
}

.required-red {
  color: #db2828;
}

// ------------------- location css starts ----------------------

.location-search-input,
.location-search-input:focus,
.location-search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.autocomplete-dropdown-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;

  z-index: 1000;
  position: absolute;
  margin-left: 40px;
}
i.massive.icons {
  font-size: 1em;
}

.suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.suggestion-item--active {
  background-color: #fafafa;
}

// ------------------- location css ends ----------------------

// ------------------- Media queries --------------------------

@media (max-width: 768px) {
  .ui.ui.header {
    font-size: large;
    height: auto;
  }
}

@media (min-width: 768px) {
  .ui.container.workspace-place-order-container {
    width: 80% !important;
  }
}

// ---------------- address confirmation modal -----------------

.field.address-modal {
  border-radius: 5px;
  padding: 5px;
}

// To Do Nest these in parent classes //
.field.address-modal.suggested {
  background: #e8e8e8;
}

.field.address-modal.entered {
  margin-bottom: 1rem;
}

// ---------------- Form error message -------------------------

.form-error-message {
  background: #e01b32;
  border-radius: 4px;
  bottom: -100px;
  left: 50%;
  padding: 12px 24px;
  position: fixed;
  transform: translateX(-50%);
  transition-duration: 0.5s;
}

.form-error-message > p {
  color: #ffffff;
  font-weight: 600;
}

.form-error-message.active {
  animation: slide-in-and-out 6s;
  animation-fill-mode: forwards;
}

@keyframes slide-in-and-out {
  0% {
    bottom: -100px;
  }

  10% {
    bottom: 32px;
  }

  90% {
    bottom: 32px;
  }

  100% {
    bottom: -100px;
  }
}
