.login-screen-details {
  margin-top: 10rem;
  margin-left: 2rem;
}

// .ui.container {
//   width: 100% !important;
// }

.login-image {
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("./Images/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-top: -0.2rem;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.action-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.action-buttons > button:first-child {
  margin-right: 16px;
}

.action-buttons > button.button.ui.fluid.button.login-button.secondary {
  background: none;
  border: 2px solid #fff;
  padding: 12px 24px;
  transition-duration: 0.3s;
}

// .action-buttons > button.button.ui.fluid.button.login-button.secondary:hover {
//   background: #fff;
//   color: #1bb595;
// }

.main-header {
  animation-duration: 0.4s;
  animation-name: slidein;
  font-size: calc(2rem + 2vw);
  color: white;
  font-weight: 5em;
  cursor: default;
}
.sub-header {
  font-size: calc(1.5rem + 2vw);
  font-weight: 5em;
  color: white;
}
@keyframes slidein {
  from {
    margin-top: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.button.ui.fluid.button.login-button {
  background: #1bb595;
  border-radius: 50px;
  color: #fff;
  font-size: 1.25rem;
  min-width: 200px;
  width: fit-content;
}

@media (max-width: 1024px) {
  .login-image {
    height: 100vh;
  }
  .login-image {
    position: absolute;
  }
}

@media only screen and (max-width: 767px) {
  .login-screen-details {
    margin-top: 10rem;
    margin-left: 0;
    padding: 0 16px;
  }

  .button.ui.fluid.button.login-button {
    min-width: fit-content;
    width: 120px;
  }
}
